import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import jsPDF from 'jspdf'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { useParams, useLocation } from 'react-router-dom'
import {
  clockInOut,
  companyDetails,
  currentlyClockIn,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  profileImg,
  quoteAssignedToUrl,
  quoteDelete,
  quoteFetchAssignedToUrl,
  updateCustomer,
  updateDueDateQuoteUrl,
  updateQuote,
  viewQuote,
} from '../../modules/auth/core/_requests'
import Box from '@mui/material/Box'
import { QuotesModel } from '../../modules/model/quotes'
import { If } from 'react-if'
import { CustomerModel } from '../../modules/model/customer'
import TextField from '@mui/material/TextField'
import { employeeList } from '../../modules/auth/core/_requests'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  TimeSheetConstant,
  TimeSheetTextConstant,
  TimeSheetInitialConstant,
  CONST_FILED_SERVICE_CREATED,
  CONST_WORK_ORDER_CREATED,
  CONST_COMPLETE_WORK_ORDER,
} from '../../context/TimeSheetConstant'
import { ProfileContext } from '../../../_metronic/layout/MasterLayout'
import ProgressBar from 'react-bootstrap/esm/ProgressBar'
import blank from '../../../_metronic/layout/components/header/upload.png'
import { Button, Modal, Row } from 'react-bootstrap'
import { StatusOpt } from './StatusOpt'
import { ErrorType } from '../../modules/errors/ErrorAlert'
import { SuccessType } from '../../modules/errors/SuccessAlert'
import {
  HAS_QuoteUpdateStatus,
  HAS_WorkOrderUpdateStatus,
  HAS_FieldServiceUpdateStatus,
  HAS_THREE_TAB_PERMISSION,
  HAS_CONTRA_THREE_TAB_PERMISSION,
} from './PermissionFunction'
import { ClockInModal } from '../../../_metronic/layout/components/header/ClockInModal'
import { QuoteModal } from './QuoteModal'
import { ClockOutModal } from '../../../_metronic/layout/components/header/ClockOutModal'
import { LinearProgress } from '@mui/material'
import EmpInterface from '../../modules/emp/EmpInterface'
import { UpdateQuoteModal } from './UpdateQuoteModal'
import moment from 'moment'
import { Editor } from '@tinymce/tinymce-react'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
}

const ProfileHeader: React.FC = ({ cbFnc, setModalOpen }: any) => {
  const { currentUser } = useAuth()
  const profileContext: any = useContext(ProfileContext)
  const [updateDueDate, setUpdateDueDate] = useState(false)
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const location: any = useLocation()
  const { type, id }: any = useParams<any>()
  const [dueDate, setDueDate] = useState('')
  const TIMESHEET_TYPE: any =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetConstant.QUOTE
      : type === TimeSheetTextConstant.WORKORDER
        ? TimeSheetConstant.WORKORDER
        : TimeSheetConstant.FIELDSERVICE
  const TIMESHEET_TEXT =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetTextConstant.QUOTE
      : type === TimeSheetTextConstant.WORKORDER
        ? TimeSheetTextConstant.WORKORDER
        : TimeSheetTextConstant.FIELDSERVICE
  const inputFile = useRef<any>()
  const [data, setData] = useState<any>([])
  const [compLogo, setCompLogo] = useState()
  const [value, setValue] = useState<any>(0)
  const [loading, setLoading] = useState(false)
  const [updateQuoteModal, setUpdateQuoteModal] = useState({
    show: false,
    data: '',
    type: 'description',
  })
  const [topping, setTopping] = useState<any>('CONST_WORK_ORDER_CREATED')
  const [acceptedModal, setAcceptedModal] = useState({ show: false, data: null })
  const MAX_CORRECT = 100
  const [img, setImg] = useState<any>('/media/custom/upload.png')
  const [hour, setHour] = useState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [maxScore] = useState(MAX_CORRECT)
  const [score, setScore] = useState<any>(0)
  const [progressBarValue, setProgressBarValue] = useState(0)
  const navigate = useNavigate()
  const [popupModal, setPopupModal] = useState<any>(false)
  const [showModal, setShow] = useState(false)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [notestatus, setNoteStatus] = useState<any>('')
  const [updation, setUpdation] = useState('')
  const [showOutModal, setOutModal] = useState(false)
  const [emplist, setEmplist] = useState<EmpInterface[]>([])
  const [descriptor, setDescriptor] = useState<boolean>(false)
  const [clocker, setClocker] = useState()
  const [clockr, setClockr] = useState()
  const [tooltip, setTooltip] = React.useState(false)
  const [customerDetails, setCustomerDetails] = useState<object | any>({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [visibleAddButton, setVisibleAddButton] = useState(
    localStorage.getItem('showAddButton') === 'true'
  )

  const onCloseUpdateQuoteModal = useCallback(() => {
    setUpdateQuoteModal({
      show: false,
      data: '',
      type: 'description',
    })
    callbackFnc()
  }, [])
  const openStatusModal = () => {
    setAnchorEl(null)
    setOpenModal(true)
  }

  const fetcher: any = (val: any) => {
    setUpdation(val.target.value)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const propTypes = {
    percent: PropTypes.number.isRequired,
  }

  const PercentBar = ({ percent, ...props }: any) => (
    <ProgressBar {...props} now={percent} min={0} max={100} />
  )

  PercentBar.propTypes = propTypes
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmit = useCallback(() => {
    setOutModal(false)
    setShow(false)
    try {
      fetchData(randomTrueFalse())
      let additional_notes = localStorage.getItem('clockIn')
      async function getData() {
        setLoading(true)
        await clockInOut(id, TIMESHEET_TYPE, additional_notes)
          .then((res) => {
            setValue(res?.data?.data)
            setTimeout(() => {
              setLoading(false)
              profileContext?.state?.setClockIn((prev: any) => !prev)
              // window.location.reload()
            }, 0)
          })
          .catch((e) => {
            console.error(e)
            darkErrorMessage('Error in Clocked in')
          })
      }
      localStorage.setItem('clockIn', '')
      getData()
    } catch (error: any) {
      console.error(error)
    }
  }, [id])

  const getEmp = useCallback(async () => {
    setLoading(true)
    await employeeList(1)
      .then((res) => {
        const employes = res.data.employees as EmpInterface[]
        setEmplist(employes)
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  const getList = useCallback(async () => {
    await viewQuote(id)
      .then((res) => {
        console.log(res)
        let fields = res.data.customer_details as QuotesModel
        let notes_data = res.data.notes as QuotesModel[]
        let profile_pic = res?.data?.customer_details?.profile_pic
        if (profile_pic != null) {
          setImg(profile_pic)
        }

        setCustomerDetails(res.data.customer_details.customer)
        const sum = res?.data?.total_hours?.sum
        if (sum != null) {
          setHour(sum)
        }
        setData(fields)
        setValue(res?.data?.user_status?.status)
        res?.data?.user_status?.status ? setValue(1) : setValue(0)
      })
      .catch(() => { })
  }, [id])

  useEffect(() => {
    cbFnc(data, TIMESHEET_TEXT, emplist)
  }, [data, TIMESHEET_TEXT, emplist, cbFnc])

  useEffect(() => {
    currentlyClockIn().then((va) => {
      const id = va?.data?.clocked_in?.quote?.id
      const isd = va?.data?.clocked_in?.final_title
      const isdr = va?.data?.clocked_in_timesheet?.final_title
      setClocker(isd)
      setClockr(isdr)
      id ? setDescriptor(true) : setDescriptor(false)
      let x: any = data?.id ? data.id : id
      if (data?.id === x) {
        id ? setValue(1) : setValue(0)
      }
    })
  }, [value])

  useEffect(() => {
    setScore((prevScore: any) => Math.max(0, Math.min(prevScore, maxScore)))
  }, [maxScore])

  useEffect(() => {
    setInterval(function () {
      let val = progressBarValue
      val += Math.floor(Math.random() * 10) + 5

      if (val >= 100) {
        val = 100
      }

      setProgressBarValue(val)
    }, 1000)
  }, [])

  const fetchData = (correct: boolean) => {
    if (score < 100) {
      correct && setScore(() => (Number(hour) / (3600 * 24)) * 100)
    }
  }
  const randomTrueFalse = () => Math.random() >= 0.333

  const callbackFnc = useCallback(() => {
    getList()
    getEmp()
  }, [getList, getEmp])

  useEffect(() => {
    callbackFnc()
  }, [callbackFnc])

  const getData = useCallback(
    async (e: any) => {
      // eslint-disable-next-line no-self-assign
      await updateQuote(id, e, 'status', notestatus)
        .then((result) => {
          let fields: any = result.data.customer_details as QuotesModel[]
          setAcceptedModal({
            show: false,
            data: null,
          })
          setData(fields)
          setNoteStatus('')
          setOpenModal(false)
          getList()
          // navigate(`/${prefixCompany}/${TIMESHEET_TEXT}`)
          window.location.reload()
        })
        .catch((e) => {
          console.error(e)
        })
    },
    [TIMESHEET_TEXT, getList, id, navigate, notestatus, prefixCompany]
  )

  const updateStatus = useCallback(
    (e: any) => {
      e === 'Accepted'
        ? setAcceptedModal({
          ...acceptedModal,
          show: true,
          data: e,
        })
        : getData(e)
      profileContext?.state?.setStatus(true)
      setNoteStatus('')
    },
    [acceptedModal, getData, profileContext?.state]
  )

  const onModalClose = useCallback(() => {
    setShow(false)
    setModalShow(false)
    setOutModal(false)
  }, [])
  const navigateDetails = () => {
    navigate(`/${prefixCompany}/customers/view/${customerDetails.id}`)
  }

  const companyList = () => {
    companyDetails()
      .then((res) => {
        setCompLogo(res?.data?.company_data?.company_logo)
      })
      .catch((e) => {
        console.error(e)
      })
  }
  useEffect(() => {
    companyList()
  }, [])

  const onOptionChange = (e: { target: { value: any } }) => {
    setTopping(e.target.value)
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }
  async function updateCustomerPic(customer_info: any, customer_id: any) {
    await updateCustomer(customer_info, customer_id)
      .then(() => { })
      .catch((e) => {
        console.error(e)
      })
  }

  function checkClockIn() {
    currentlyClockIn().then((va) => {
      if (va?.data?.clocked_in_timesheet?.final_title) {
        // setCurrentVal(va?.data?.clocked_in_timesheet?.final_title)
      }
    })
    value ? setOutModal(true) : setShow(true)
  }
  // const checkClockIn = useCallback(() => {
  //   currentlyClockIn().then((va) => {
  //     if (va?.data?.clocked_in_timesheet?.final_title) {
  //       // setCurrentVal(va?.data?.clocked_in_timesheet?.final_title)
  //     }
  //   });
  //   value ? setOutModal(true) : setShow(true);
  // }, [currentlyClockIn ]);
  // useEffect(() => {
  //   checkClockIn()
  // }, [checkClockIn])
  const changeImg = (e: any) => {
    const user_img = e?.target?.files
    try {
      setLoading(true)
      let filesToUpload: any = []
      let lengthOfFiles = Object.keys(user_img).length
      if (user_img != null || lengthOfFiles > 0) {
        let img_kb = user_img[0].size / 1024
        if (img_kb > 1024 * 2) {
          setErrorBody({
            error: true,
            error_list: [
              `
             Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
                user_img[0]?.size /
                1024 /
                1024
              ).toFixed(2)}MB
            `,
            ],
          })
          darkErrorMessage(`
          Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
              user_img[0]?.size /
              1024 /
              1024
            ).toFixed(2)}MB
         `)
          return
        }
        Object.values(user_img).map((value: any) => {
          return filesToUpload.push(value)
        })
      } else {
        console.error('file is not selected')
      }
      profileImg(filesToUpload, prefixCompany)
        .then((res) => {
          let customer: CustomerModel = {
            id: data?.customer?.id,
            profile_pic: res?.data?.user_pic,
            first_name: data?.customer?.first_name,
            last_name: data?.customer?.last_name,
            company: data?.customer?.company,
            company_phone: data?.customer?.company_phone,
            email: data?.customer?.email,
            phone: data?.customer?.phone,
            address: data?.customer?.address,
            city: data?.customer?.city,
            state: data?.customer?.state,
            zip_code: data?.customer?.zip_code,
            contact_method: data?.customer?.contact_mehtod,
            discount_type: data?.customer?.discount_type,
            referral_type: data?.customer?.referral_type,
            notes: data?.customer?.notes,
            address_line_2: data?.customer?.address_line_2,
            billing_address_1: data?.customer?.billing_address_1,
            billing_address_2: data?.customer?.billing_address_address_2,
            billing_city: data?.customer?.address_city,
            billing_state: data?.customer?.address_state,
            billing_zip_code: data?.customer?.zip_code,
            website: data?.customer?.website,
            cus_pros: data?.customer?.cus_pros || 'customer',
            fieldArr: JSON.parse(data?.customer?.fieldArr),
            fields: [],
          }
          setLoading(false)
          updateCustomerPic(customer, customer?.id)
          setImg(URL.createObjectURL(e.target.files[0]))
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
        })
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  let cDescriptor = descriptor
    ? `Clocking In to ${type === TimeSheetTextConstant?.QUOTE
      ? TimeSheetInitialConstant.QUOTE
      : type === TimeSheetTextConstant?.WORKORDER
        ? TimeSheetInitialConstant.WORKORDER
        : TimeSheetInitialConstant.FIELDSERVICE
    }${data?.label
    } will clock you out of currently clocked in ${clocker} , do you wish to continue?`
    : clockr
      ? `Clocking In to ${type === TimeSheetTextConstant?.QUOTE
        ? TimeSheetInitialConstant.QUOTE
        : type === TimeSheetTextConstant?.WORKORDER
          ? TimeSheetInitialConstant.WORKORDER
          : TimeSheetInitialConstant.FIELDSERVICE
      }${data?.label} will clock you out from Shop-time , do you wish to continue?`
      : `Clocking In to ${type === TimeSheetTextConstant?.QUOTE
        ? TimeSheetInitialConstant.QUOTE
        : type === TimeSheetTextConstant?.WORKORDER
          ? TimeSheetInitialConstant.WORKORDER
          : TimeSheetInitialConstant.FIELDSERVICE
      }${data?.label}, do you wish to continue?`
  const pdfGenerate = async () => {
    const pdf = new jsPDF('portrait', 'pt', 'a4')
    console.log(currentUser)
    const header = `<br>
      <img style="float:left;width:150px;height:175px" src=${compLogo} />
    <div style="margin-left:160px; font-size:10px; width:430px">
      <table width="100%">
        <tr style="padding-bottom: 15px;">
        <th style="color: rgb(143,143,143); font-weight: normal; font-size: 9px;">Quote#</th>
        <th style="color: rgb(143,143,143); font-weight: normal; font-size: 9px;">Issued by:</th>
        <th style="color: rgb(143,143,143); font-weight: normal; font-size: 9px;">Prepared By:</th>
        <th style="color: rgb(143,143,143); font-weight: normal; font-size: 9px;">Quote point of contact</th>
        </tr>        
        <tr>
        <td style="vertical-align:top;color: rgb(85,85,85);font-weight:bold; line-height: 1.2; font-size: 9px;">${data?.id}</td>
        <td style="color: rgb(85,85,85); line-height: 1.2; font-size: 9px;">
          <b style="font-size: 9px;">${currentUser?.company}</b>
          <br>${currentUser?.company_address_line1}
          <br>${currentUser?.company_address_line2}<br>
        </td>
        <td style="vertical-align:top;color: rgb(85,85,85);font-weight:bold; line-height: 1.2; font-size: 9px;">
          Prepared By
        </td>
        <td style="color: rgb(85,85,85); line-height: 1.2; font-size: 9px;">
          <b>Chris Rath</b>
          <br/>727-216-6416
          <br/>Chris@icontrols.com
          </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <tr>
        <th style="color: rgb(143,143,143); font-weight: normal;line-height: 1.2; font-size: 9px;">Date</th>
        <th style="color: rgb(143,143,143); font-weight: normal; margin-top: 30px; font-size: 9px;">Issued for:</th>
        <th style="color: rgb(143,143,143); font-weight: normal; font-size: 9px;">Approved By:</th>
        </tr>
        <tr>
        <td style="color: rgb(85,85,85); font-weight:bold; line-height: 1.2; vertical-align: top; letter-spacing: 0px; font-size: 9px;">08/05/2022</td>
        <td style="color: rgb(85,85,85); line-height: 1.2; font-size: 9px;">
        <b style="font-size: 9px">Fives Automation</b>
        <br>23400 Halsted Rd
        <br>Farmington Hills, MI 48335
        </td>
        <td style="vertical-align:top;color: rgb(85,85,85);font-weight:bold; line-height: 1; font-size: 9px;">
        Approved By
        </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <tr>
        <th style="color: rgb(143,143,143); font-weight: normal; font-size: 9px;">Exp. Date</th>
        </tr>
        <tr>
        <td style="color: rgb(85,85,85);font-weight:bold; line-height: 1; letter-spacing: 0; font-size: 9px;">10/10/2022</td>
        </tr>
      </table>
    </div>
    <br>
`
    const html = `
    <!doctype html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    </head>
    ${header}    
    <div style="margin-right:15px; width:95%;">
      <table style="width:785px ; margin-left:15px; margin-right:30px;">
        <tr>
          <td>
            <h6 style=" padding: 0px;margin-top:0px;color:rgb(135,135,135);font-weight:bold;word-spacing: -5px">Quote # ${data?.id} - Initial Intake Form</h6>
            <hr style="height:0.5px;margin-top:8px;color:rgb(223,223,223)" />
          </td>
        </tr>
      </table>
    </div>
    <div style="width:595px;margin-left:15px;font-size:10px;margin-right:15px">
    <table width="100%">
    <tr>
    <th style="padding-right: 65px;color: rgb(132,132,132); font-weight: normal;">Project Name </th>
    <th style="color: rgb(132,132,132); font-weight: normal;">Replacing Existing Panel or New Build?</th>
    <th style="color: rgb(132,132,132); font-weight: normal;">how many panel are needed ?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.project_name}</td>
    <td style="font-weight:bold">${data?.fields?.panel_type}</td>
    <td style="font-weight:bold">${data?.fields?.panel_needed}</td>
    </tr>
    <tr>
    <th style="padding-right: 65px;color: rgb(132,132,132); font-weight: normal;">Power in Voltage</th>
    <th style="color: rgb(132,132,132); font-weight: normal;">Control Voltage</th>
    <th style="color: rgb(132,132,132); font-weight: normal;">Phase</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_power_voltage}</td>
    <td style="font-weight:bold">${data?.fields?.panel_control_voltage}</td>
    <td style="font-weight:bold">${data?.fields?.panel_phase}</td>
    </tr>
    </table>
    </div>

    <div style="width:595px;margin-left:15px;font-size:10px;margin-right:15px">
    <table>
    
    <th style="color: rgb(132,132,132); font-weight: normal;">How many motors will this control panel be running?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_runing}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">HP of the motors?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_hp_motors}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Power of voltage?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_voltage_motors}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Do you have pictures, drawings, layouts, motor nameplate or any other reference info : </th> 
    </th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_layout}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Do you have your own electrician on-site that will install the panel :</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_own_electician}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">When are you expecting to have the completed project?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_expecting}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">What is the desired NEMA environmental rating of this Control Panel?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_environmental}</td>
    </tr>
    
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">What is the available short-circuit current that the Control Panel will be designed for?</th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_electical}</td>
    </tr>
    
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Does the control panel need to be designed to meet any arc flash Category?
    </th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_flash_category}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Where will be the Control panel be installed?
    </th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_installed}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Do you have manufacturar requirements for PLCs, HMIs, VFDs, Starters?
    </th>
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_have_manufacturar}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">What are the current manufacturers used for PLCs, HMIs, VFDs, Starters?
    </th> 
    </tr>
    <tr>  
    <td style="font-weight:bold">${data?.fields?.panel_current_manufacturar}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Does this panel require any special components that we did not already discuss?
    </th> 
    </tr>
    <tr>
    <td style="font-weight:bold">${data?.fields?.panel_special_components}</td>
    </tr>
    <tr>
    <th style="color: rgb(132,132,132); font-weight: normal;">Project Description</th>
    </tr>
    <tr>
    <td style="font-weight:bold;height:90px;vertical-align:top;">${data?.fields?.panel_description}</td>
    </tr>
    </table>
    <div>
    
    <br>    
    ${header}    
    <div style="margin-right:15px; width:100%;">
      <table style="width:785px ; margin-left:0; margin-right:30px;">
        <tr>
          <td>
            <h6 style=" padding: 0px;margin-top:0px;color:rgb(135,135,135);font-weight:bold;word-spacing: -5px">Quote # ${data?.id} </h6>
            <hr style="height:0.5px;margin-top:8px;color:rgb(223,223,223)" />
          </td>
        </tr>
      </table>
    </div>
    

  <div style="margin-right:15px ;">
    <table style="width:90% ; margin-left:35px">

    <tr style="border-bottom:1px solid #ccc">
    <th style="font-weight: normal; color: #4a4a4a;"><img style="width: 10px; height: 10px; margin-left: -25px; margin-right: 10px" src="/media/custom/circle-1.png"> Parts /Material</th>
    <th style="color: #989faa;">Qty</th>
    <th style="color: #989faa;">Rate</th>
    <th style="color: #989faa;">Total</th>
    </tr>
   
    <tr>
    <td style="color: #4a4a4a;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - PartName</td>
    <td style="color: #4a4a4a;">4</td>
    <td style="color: #4a4a4a;">$50</td>
    <td style="color: #4a4a4a;">$200</td>
    </tr>
  

    <tr><td><pre> </pre><td></tr>

    <tr style="border-bottom:1px solid #ccc">
    <th style="font-weight: normal; color: #4a4a4a;"><img style="width: 10px; height: 10px; margin-left: -25px; margin-right: 10px" src="/media/custom/circle-2.png"> Labour Hours</th>
    </tr>

    <tr>
    
    <td style="color: #4a4a4a;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Labour Details</td>
    <td style="color: #4a4a4a;">10</td>
    <td style="color: #4a4a4a;">$50</td>
    <td style="color: #4a4a4a;">$500</td>
    </tr>
   
    <tr><td><pre> </pre><td></tr>
    
    <tr style="border-bottom:1px solid #ccc">
    <th style="font-weight: normal; color: #4a4a4a;"><img style="width: 10px; height: 10px; margin-left: -25px; margin-right: 10px" src="/media/custom/circle-3.png"> Paid Services</th>
    </tr>

    <tr>
    <td style="color: #4a4a4a;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - PartName</td>
    <td style="color: #4a4a4a;">50</td>
    <td style="color: #4a4a4a;">$80</td>
    <td style="color: #4a4a4a;">$400</td>
    </tr>



    </table>
    <br/>
    <br/>
    <br/>
    <div style="float:right;margin-right:72px;width:30%"><hr style="background-color: #ccc; color: #dfdfdf; height: 3px; border-radius: 4px"></hr> </div>
    <br>
    <br>
    <table style="float:right;margin-right:72px; margin-top:-5px;width:30%; cellspacing=12">
    <tr><td style="text-align: right; color: #989faa; font-weight: bold;">SUB TOTAL :</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1100</td></tr>
    <tr><td style="text-align: right; color: #989faa; font-weight: bold;">TAX :</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$250</td></tr>
    <tr><td style="text-align: right; color: #989faa; font-weight: bold;">GRAND TOTAL :</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1350</td></tr>
    </table>

    <br><br><br><br><br><br><br><br>  
    <br><br><br><br><br><br><br><br>  
    
    <table>
    <tr>
    <th style="color: #555555; line-height: 1.2; font-size: 14px">Disclaimer</th>
    </tr>
    <tr>
    <td style="color: #555555; line-height: 1; font-size: 13px">All items on this quote are estimated and subject to change based on</td>
    </tr>
    <tr>
    <td style="color: #555555; line-height: 1; font-size: 13px">time of approval, vendor pricing, product availability, and other market factors.</td>
    </tr>
    </table>
    
  </div>
  
  <br>
  <br><br><br>
  <br>
  
  ${header}  
  <div style="margin-right:15px; width:100%;">
    <table style="width:785px ; margin-left:0; margin-right:30px;">
      <tr>
        <td>
          <h5 style=" padding: 0px;color:rgb(135,135,135);margin-top:0px;font-weight:bold;word-spacing: -5px">Quote # ${data?.id} - Estimate Notes</h5>
          <hr style="height:0.5px;margin-top:8px;color:rgb(223,223,223)" />
        </td>
      </tr>
    </table>
  </div>
  <div>
    <table style="width: 90%;">
    <tr>
    <td>
    <ol>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">We recommend the safety floor mats specifically because of the requirement that one (and only one)
    operator can be standing anywhere on the operator side of the machine when the guard will be
    allowed to close. This requirement is difficult to achieve with the floor scanner.</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">The overall dimensions of the safety floor mat is 3 meters (=9.8') long by 0.5 meters (=20")wide, and
    would have 6 individual floor mats that are each 20" x 20". The system would allow for any one safety
    floor mat (20"x20") to have an operator present to close the guard, but the guard will not be allowed to
    close if two safety floor mats have an operator present.</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">The floor mats have trim that allows for connection to the floor, thereby preventing the door mats from
    changing position.</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">We are assuming that the existing Guardmaster safety controller on the machine can be used for the
    satety devices being proposed,but we are adding l/O cards to the existing safety controller (assuming
    they have room for the additional l/O cards, but this is not confimed yet).</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">Quotes include the engineering time for drawings, etc., and programming the Guardmaster safety
      controller.</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">The customer will need to mechanically mount the door safety switches above the guard (when in the
        open position) by means of custom mount brackets. We could provide these mounting brackets, but
        these braCKets are not currenty indluded in the quote.</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">We are using all Allen Bradley safety components.</li>
    <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">The quote is for 1 machine, and the installation would happen over 2 consecutive days with one trip
      Over and one trin back</li>  
    </ol>
    </td>
  </tr>  
</table>
    </div>
    <div>
    </br>
      <span style="font-weight:bold; display: inline-block; color: #444; font-size: 13px; border-bottom: 1px solid #444; width: auto; margin-bottom: 10px">Please note that we are assuming the following</span>
      <table style="width: 90%;">
       <tr>
       <td>
       <ol>
       <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">Each machine has room for 3 additional Guardlogix l/0 cards.</li>
       <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">Braun has the existing Guardlogix software that we can modify.</li>
       <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">The length of the protection area in front of the machine is 10 feet
       (6 operator zones that are each 20" wide).</li>
       <li style="line-height: 1.2;color: rgb(61,61,61);text-align: justify;word-spacing: -1px;margin-bottom: 10px; font-size: 12px">Braun fully agrees with the safety system we are proposing.</li>

       </ol>
       </td>
       </tr>
      </table>
          </div>

    </html>
    `

    pdf.html(html).then(() => {
      pdf.save('pdf.pdf')
    })
  }

  const deleteQuote = (id: any) => {
    quoteDelete(id)
      .then((res) => {
        let result = res?.data?.error
        setSuccessBody(result as any)
        darkMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} deleted Successfully`)
        navigate(`/${prefixCompany}/${type}`)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
        }
      })
  }

  const handleDueDate = useCallback(() => {
    updateDueDateQuoteUrl(id, dueDate)
      .then((res) => {
        const message = res?.data?.message
        darkMessage(message)
        setUpdateDueDate(false)
        callbackFnc()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [callbackFnc, dueDate, id])

  const handleModal = useCallback(
    (index: any, addData: any) => {
      console.log('dddd', id, index, null)
      updateQuote(id, index, 'status', null)
        .then(handleClose)
        .then(() => {
          navigate(`/${prefixCompany}/${type}`)
          darkMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} updated Successfully.`)
        })
      setPopupModal(false)
    },
    [id, navigate, prefixCompany, type]
  )

  const handleToppings = useCallback(() => {
    const index = topping
    topping === 'None'
      ? getData('Accepted')
      : updateQuote(id, index, 'status', null)
        .then(handleClose)
        .then(() => {
          navigate(`/${prefixCompany}/${type}`)
          darkMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} updated Successfully.`)
        })
    setAcceptedModal({
      show: false,
      data: null,
    })
  }, [id, navigate, prefixCompany, topping, type])

  const [modal, setModalShow] = React.useState<any>({
    show: false,
    id: null,
    check: false,
  })

  const updateLabel = (e: any, data: any) => {
    setAnchorEl(null)
    setModalShow({ show: true, id: e, check: data })
  }
  const handleClosed = () => {
    setTooltip(false)
  }

  const handleOpened = () => {
    setTooltip(true)
  }

  const quotesClicked = () => {
    setVisibleAddButton(true)
    localStorage.setItem('showAddButton', 'true')
  }

  // const handleAddShow = () => setAddShow(true)
  // const handleAddClose = () => setAddShow(false)

  // const MaterialColumns: any = useMemo(
  //   () => [
  //     {
  //       accessorFn: (row: QuotesModel) => 'Q-' + row.label,
  //       size: 70,
  //       header: 'Quotes', //uses the default width from defaultColumn prop
  //       accessorKey: 'quote_id',
  //     },
  //     {
  //       accessorFn: (row: QuotesModel) => row.customer.company,
  //       header: 'Customer',
  //       accessorKey: 'customer_name',
  //     },
  //     {
  //       header: 'Project Name',
  //       accessorFn: (row: QuotesModel) => row.project_name,
  //       size: 200, //increase the width of this column
  //       accessorKey: 'project_name',
  //     },
  //     {
  //       header: 'Assigned To',
  //       accessorFn: (row: QuotesModel) =>
  //         row?.assigned_to ? (
  //           row?.assigned_to.map((eachUser) => {
  //             return (
  //               <Tooltip
  //                 title={
  //                   <span style={{fontSize: '15px'}}>
  //                     {eachUser?.first_name + '  ' + eachUser?.last_name || 'N/A'}
  //                   </span>
  //                 }
  //                 className='p-2 '
  //               >
  //                 <span>
  //                   <img
  //                     alt='emp logo'
  //                     src={`${eachUser?.profile_image || '/media/avatars/blank.png'}`}
  //                     onError={(evt: any) => {
  //                       evt.target.src = blank
  //                     }}
  //                     width='40px'
  //                     height={'40px'}
  //                     className='mx-1'
  //                     style={{objectFit: 'contain', borderRadius: '50%'}}
  //                   />
  //                 </span>
  //               </Tooltip>
  //             )
  //           })
  //         ) : (
  //           <></>
  //         ),
  //       size: 200, //increase the width of this column
  //       accessorKey: 'assigned_to',
  //     },
  //     {
  //       accessorFn: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
  //       header: 'Date Created',
  //       size: 100, //decrease the width of this column
  //       accessorKey: 'date_created',
  //     },
  //     {
  //       accessorFn: (row: QuotesModel) => moment(row.date_updated, 'DD-MM-YYYY').format('ll'),
  //       header: 'Last Modified',
  //       size: 100, //decrease the width of this column
  //       accessorKey: 'last_modified',
  //     },
  //     {
  //       accessorFn: (row: QuotesModel) => (
  //         <Link to={`/${prefixCompany}/project/${row?.id}/overview`} state={{data: row}}>
  //           <span
  //             style={{
  //               backgroundColor: `${row.status_type.bgcolor ?? '#D0E0CF'}`,
  //               color: `${row.status_type.color ?? '#054701'}`,
  //               fontSize: '11px',
  //               textAlign: 'center',
  //               padding: '5px 15px',
  //               display: 'block',
  //               maxWidth: 'fit-content',
  //             }}
  //             title={row.status_type.title}
  //           >
  //             {row.type}
  //           </span>
  //         </Link>
  //       ),
  //       header: 'Status',
  //       size: 170, //decrease the width of this column
  //       accessorKey: 'quote_status',
  //     },
  //   ],
  //   []
  // )

  // const handleChange = useCallback((event: any) => {
  //   const value = event.target.value
  //   if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
  //     setSelected(
  //       selected.length === option.length
  //         ? []
  //         : option.map((item: {title: any}) => {
  //             return item.title
  //           })
  //     )
  //     getListV2(0, '', SELECTED_ALL)
  //     return
  //   } else {
  //     setSelected(value)
  //     getListV2(0, '', value)
  //   }
  // }, [])

  // const handleTagFunctionality = useCallback((tag_data: string[] | string) => {
  //   getListV2(0, '', SELECTED_ALL, 100, tag_data)
  // }, [])

  // const openModalV2 = () => {
  //   setModalShowV2(1)
  //   setAnchorElV2(null)
  // }



  return (
    <div className='card mb-5 mb-xl-10 p-5 pt-0 '>
      {/* {console.log(JSON.stringify(HAS_FieldServiceUpdateStatus(currentUser)))} */}
      <Modal
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <QuoteModal
          onModalClose={onModalClose}
          callbackFnc={callbackFnc}
          show={modal?.show}
          id={modal?.id}
          checked={modal?.check}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      {HAS_QuoteUpdateStatus(currentUser) && TIMESHEET_TEXT === TimeSheetTextConstant?.QUOTE && (
        <Modal
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
          onModalClose={() => {
            setOpenModal(false)
          }}
          label={'somelable'}
          show={openModal}
          onHide={() => setOpenModal(false)}
          // size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <div>
            <Box sx={style}>
              <h3>Change {type.charAt(0).toUpperCase() + type.slice(1)} Status</h3>
              <hr></hr>
              <StatusOpt fetcher={fetcher} />
              <div style={{ paddingTop: '2em' }}>

                <Editor 
                  tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                  onEditorChange={(stringifiedHtmlValue) => {
                    setNoteStatus(stringifiedHtmlValue)
                  }}
                  initialValue=''                  
                  init={{
                    placeholder: 'Enter Status Note',
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
                <Button
                  style={{ margin: '2em auto 0', display: 'block' }}
                  onClick={() => {
                    updateStatus(updation)
                  }}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </div>
        </Modal>
      )}
      {HAS_WorkOrderUpdateStatus(currentUser) &&
        TIMESHEET_TEXT === TimeSheetTextConstant?.WORKORDER && (
          <Modal
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
            onModalClose={() => {
              setOpenModal(false)
            }}
            label={'somelable'}
            show={openModal}
            onHide={() => setOpenModal(false)}
            // size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <div>
              <Box sx={style}>
                <h3>Change {type.charAt(0).toUpperCase() + type.slice(1)} Status</h3>
                <hr></hr>
                <StatusOpt fetcher={fetcher} />
                <div>
                  <TextField
                    className='my-3'
                    label='Enter Status Note'
                    fullWidth
                    variant='outlined'
                    onChange={(e) => {
                      setNoteStatus(e.target.value)
                    }}
                  ></TextField>
                  <Button
                    style={{ marginLeft: '40%', marginTop: 3 }}
                    onClick={() => {
                      updateStatus(updation)
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </div>
          </Modal>
        )}
      {HAS_FieldServiceUpdateStatus(currentUser) &&
        TIMESHEET_TEXT === TimeSheetTextConstant?.FIELDSERVICE && (
          <Modal
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
            onModalClose={() => {
              setOpenModal(false)
            }}
            label={'somelable'}
            show={openModal}
            onHide={() => setOpenModal(false)}
            // size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <div>
              <Box sx={style}>
                <h3>Change {type.charAt(0).toUpperCase() + type.slice(1)} Status</h3>
                <hr></hr>
                <StatusOpt fetcher={fetcher} />
                <div>
                  <TextField
                    className='my-3'
                    label='Enter Status Note'
                    fullWidth
                    variant='outlined'
                    onChange={(e) => {
                      setNoteStatus(e.target.value)
                    }}
                  ></TextField>
                  <Button
                    style={{ marginLeft: '40%', marginTop: 3 }}
                    onClick={() => {
                      updateStatus(updation)
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </div>
          </Modal>
        )}
      <ClockInModal
        show={showModal}
        onModalClose={onModalClose}
        handleSubmit={handleSubmit}
        value={value}
        timesheet_type={type}
        description={cDescriptor}
      />

      <ClockOutModal
        show={showOutModal}
        onModalClose={onModalClose}
        handleSubmit={handleSubmit}
        value={value}
        timesheet_type={type}
        description={`Clocking Out from ${TIMESHEET_TEXT === TimeSheetTextConstant?.QUOTE
            ? TimeSheetInitialConstant.QUOTE
            : TIMESHEET_TEXT === TimeSheetTextConstant?.WORKORDER
              ? TimeSheetInitialConstant.WORKORDER
              : TimeSheetInitialConstant.FIELDSERVICE
          }${data?.label} , do you wish to continue?`}
      // will clock you out of currently clocked in ${currentVal}
      // 'Clock In may Clock Out You from Other Quotes & WorkOrders Or may be from Shop Time Also.'
      />
      <Modal
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        show={updateDueDate}
        onHide={() => {
          setUpdateDueDate(false)
        }}
        size='lg'
        centered
      // className='bg-opacity-1 bg-black'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Update Due Date</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Please Enter Due Date
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  value={dueDate}
                  className='form-control form-control-lg form-control-solid'
                  onChange={(e) => setDueDate(e?.target?.value)}
                />
              </div>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-end' style={{ paddingRight: '10px' }}>
            <button onClick={() => setUpdateDueDate(false)} className='mx-5 btn btn-dark'>
              Discard
            </button>
            <button
              type='button'
              onClick={() => handleDueDate()}
              className=' mx-5 text-center btn btn-primary'
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
        {/* <UpdateQuoteModal onCloseUpdateQuoteModal={onCloseUpdateQuoteModal} /> */}
      </Modal>
      <Modal
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        show={openDeleteModal}
        onHide={() => setDeleteModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you want to delete #{data?.id} and all files attached?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={'btn-dark'}
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={'btn-primary'}
            onClick={() => {
              deleteQuote(data?.id)
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        show={acceptedModal?.show}
        onHide={() =>
          setAcceptedModal({
            ...acceptedModal,
            show: false,
          })
        }
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>Do you want to create a Project or Field Service?</h3>
            <div className='d-flex justify-content-around mt-lg-9'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='flexRadioDefault'
                  value={CONST_WORK_ORDER_CREATED}
                  id='project'
                  onChange={onOptionChange}
                  checked={topping === CONST_WORK_ORDER_CREATED}
                />
                <label className='form-check-label' htmlFor='project'>
                  Project
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  value={CONST_FILED_SERVICE_CREATED}
                  name='flexRadioDefault'
                  id='field_service'
                  onChange={onOptionChange}
                  checked={topping === CONST_FILED_SERVICE_CREATED}
                />
                <label className='form-check-label' htmlFor='field_service'>
                  Field Service
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  value={'None'}
                  name='flexRadioDefault'
                  id='None'
                  onChange={onOptionChange}
                  checked={topping === 'None'}
                />
                <label className='form-check-label' htmlFor='None'>
                  Mark Accepted Only
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setAcceptedModal({
                ...acceptedModal,
                show: false,
              })
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              handleToppings()
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        show={popupModal?.show}
        onHide={() => setPopupModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <h3>{popupModal?.body}</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setPopupModal(false)
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              handleModal(popupModal?.params, popupModal?.addData)
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        show={updateQuoteModal?.show}
        onHide={() => {
          onCloseUpdateQuoteModal()
        }}
        size='lg'
        centered
      // className='bg-opacity-1 bg-black'
      >
        <Modal.Header>
          <h1>Update {TIMESHEET_TEXT}</h1>
        </Modal.Header>
        <UpdateQuoteModal
          type={updateQuoteModal.type}
          onCloseUpdateQuoteModal={onCloseUpdateQuoteModal}
        />
      </Modal>

      <div className='d-flex h-55px' style={{ justifyContent: 'space-between' }}>
        <ul className=' text-left nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap '>
          <li className='nav-item'>
            <Link
              onClick={() => setVisibleAddButton(false)}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === `/${prefixCompany}/${type}/${id}/overview` && 'active')
              }
              to={`/${prefixCompany}/${type}/${id}/overview`}
            >
              Overview
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              onClick={() => setVisibleAddButton(false)}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === `/${prefixCompany}/${type}/${id}/files` && 'active')
              }
              to={`/${prefixCompany}/${type}/${id}/files`}
            >
              Files
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              onClick={() => setVisibleAddButton(false)}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === `/${prefixCompany}/${type}/${id}/activity` && 'active')
              }
              to={`/${prefixCompany}/${type}/${id}/activity`}
            >
              Activity
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              onClick={() => setVisibleAddButton(false)}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === `/${prefixCompany}/${type}/${id}/timesheet` && 'active')
              }
              to={`/${prefixCompany}/${type}/${id}/timesheet`}
            >
              Hours used
            </Link>
          </li>

          {HAS_CONTRA_THREE_TAB_PERMISSION(type) && (
            <>
              <>
                <li className='nav-item'>
                  <Link
                    onClick={() => setVisibleAddButton(false)}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/${prefixCompany}/${type}/${id}/purchase-order` &&
                        'active')
                    }
                    to={`/${prefixCompany}/${type}/${id}/purchase-order`}
                  >
                    Purchase Orders
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    onClick={() => setVisibleAddButton(false)}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/${prefixCompany}/${type}/${id}/items-assigned` &&
                        'active')
                    }
                    to={`/${prefixCompany}/${type}/${id}/items-assigned`}
                  >
                    Items Assigned
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link
                    onClick={() => setVisibleAddButton(false)}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/${prefixCompany}/${type}/${id}/bom-assigned` &&
                        'active')
                    }
                    to={`/${prefixCompany}/${type}/${id}/bom-assigned`}
                  >
                    Subquotes
                  </Link>
                </li> */}
              </>
            </>
          )}

          {HAS_THREE_TAB_PERMISSION(type) && (
            <>
              <li className='nav-item'>
                <Link
                  onClick={quotesClicked}
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/${prefixCompany}/${type}/${id}/bom` && 'active')
                  }
                  to={`/${prefixCompany}/${type}/${id}/bom`}
                >
                  Quotes
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className='row d-flex align-items-center my-lg-5 w-100 m-auto'>
        <div className='col-md-1 d-flex justify-content-center pb-2 p-0'>
          <div className=''>
            <div className='symbol symbol-100px  symbol-fixed position-relative'>
              <input
                type='file'
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={changeImg}
                accept='image/*'
              />

              {loading ? (
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: 'auto',
                    cursor: 'pointer',
                    objectFit: 'contain',
                  }}
                >
                  <LinearProgress />
                </div>
              ) : (
                <img
                  src={img}
                  alt='Customer Logo'
                  onClick={onButtonClick}
                  onError={() => {
                    setImg(blank)
                  }}
                  style={{
                    maxWidth: '100px',
                    width: '100%',
                    height: '100px',
                    margin: 'auto',
                    cursor: 'pointer',
                    objectFit: 'contain',
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-md-2 col-sm-6'>
          <div className=' col-form-label fs-6 text-left customer' p-3 style={{ color: '#a1a5b7' }}>
            <span>Customer</span>
            <div className=' col-form-label fw-bold fs-6 text-left p-0 cursor-pointer'>
              <Tooltip
                open={tooltip}
                onClose={handleClosed}
                onOpen={handleOpened}
                title={
                  <>
                    <span style={{ fontSize: '15px' }}>
                      Business Name :{customerDetails?.company}
                      <br />
                      Customer Name :
                      {customerDetails?.first_name + '  ' + customerDetails?.last_name || 'N/A'}
                      <br />
                      Email: {customerDetails?.email || 'N/A'}
                      <br />
                      Phone Number : {customerDetails?.phone || 'N/A'}
                      <br />
                      Address :{' '}
                      {customerDetails?.address +
                        ', ' +
                        customerDetails?.city +
                        ', ' +
                        customerDetails?.state +
                        ', ' +
                        customerDetails?.zip_code || 'N/A'}
                    </span>
                  </>
                }
              >
                <span style={{ fontSize: '16px' }} onClick={navigateDetails}>
                  {data?.customer?.company}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-sm-6'>
          <div className=' col-form-label fs-6 text-left' p-3 style={{ color: '#a1a5b7' }}>
            Project Name{' '}
            <i
              className='bi bi-pencil-square mx-2 cursor-pointer'
              style={{ color: 'black' }}
              onClick={() =>
                setUpdateQuoteModal({
                  show: true,
                  data: data,
                  type: 'description',
                })
              }
            ></i>
            <div className=' col-form-label fw-bold fs-6 text-left p-0'>
              <p className='m-0' style={{ fontSize: '16px' }}>
                {/* {console.log(data,data?.description)} */}
                {/* {data?.fields === ''? data?.description:data?.fields?.project_field} */}
                {data?.fields?.project_field}
              </p>
              {/* <p className='m-0' style={{ fontSize: '16px' }}>
                {data?.fields === '' ? data?.description : (data?.fields?.project_field || data?.project_name)}
              </p> */}
            </div>
          </div>
        </div>
        <div className='col-md-3 col-sm-6'>
          <div className='timesheet-wrap'>
            <span className='fs-6 font-weight-bold ' style={{ color: 'rgb(161, 165, 183)' }}>
              {type === TimeSheetTextConstant?.QUOTE ? (
                'Quote#'
              ) : type === TimeSheetTextConstant?.WORKORDER ? (
                'Project#'
              ) : type === TimeSheetTextConstant?.FIELDSERVICE ? (
                'Field Service#'
              ) : (
                <></>
              )}
            </span>

            <div className='d-flex' style={{ alignItems: 'center' }}>
              <div className='d-flex col-form-label fw-bold fs-6 text-left p-0'>
                <div
                  className=' col-form-label fw-bold text-left p-0 cursor-pointer'
                  style={{ fontSize: '16px' }}
                  title={'Click me to update Quote Id'}
                >
                  {TIMESHEET_TEXT === TimeSheetTextConstant.QUOTE
                    ? TimeSheetInitialConstant.QUOTE
                    : TIMESHEET_TEXT === TimeSheetTextConstant.WORKORDER
                      ? TimeSheetInitialConstant.WORKORDER
                      : TIMESHEET_TEXT === TimeSheetTextConstant.FIELDSERVICE
                        ? TimeSheetInitialConstant.FIELDSERVICE
                        : TIMESHEET_TEXT}
                  {data?.label ?? data?.id}
                </div>
              </div>
              <span
                className='badge badge-light-primary mx-lg-6'
                onClick={openStatusModal}
                style={{
                  backgroundColor: data?.type?.bgcolor ?? 'rgb(208, 224, 207)',
                  color: data?.type?.color ?? 'rgb(5, 71, 1)',
                  fontSize: '12px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                {data?.type?.title ?? data?.type}
              </span>
            </div>
          </div>
        </div>
        <div className='col-md-1 col-sm-6'>
          {TIMESHEET_TEXT === TimeSheetTextConstant?.WORKORDER && (
            <div className=' col-form-label fs-6 text-left' p-3 style={{ color: '#a1a5b7' }}>
              Due Date
              <div className=' col-form-label fw-bold fs-6 text-left p-0'>
                {data?.due_date ? moment(data?.due_date).format('ll') : 'N/A'}
                <i
                  className='fa-solid fa-angle-down mx-2 cursor-pointer'
                  style={{ color: 'black' }}
                  onClick={() => setUpdateDueDate(true)}
                ></i>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-2 col-sm-12'>
          <div className='clk-btn'>
            <div className='d-flex flex-wrap-reverse'>
              {
                <>
                  {visibleAddButton && (
                    <button
                      style={{ marginRight: '10%' }}
                      className='btn btn-primary'
                      onClick={() => setModalOpen(true)}
                    >
                      Add Quote
                    </button>
                  )}
                </>
              }{' '}
              {/* <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size='ml'>
                <Modal.Header closeButton>
                  <Modal.Title>Add Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <SubQuote setIsModalOpen={setIsModalOpen} />
                </Modal.Body>
              </Modal> */}
              <button
                className='btn-white btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <b style={{ letterSpacing: '3px', color: 'black' }}>...</b>
              </button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {type === TimeSheetTextConstant?.QUOTE && (
                  <>
                    <MenuItem
                      onClick={() => {
                        setPopupModal({
                          show: true,
                          params: CONST_WORK_ORDER_CREATED,
                          addData: 'Project',
                          body: 'Did You really want to convert this Quote to Project?',
                        })
                        setAnchorEl(null)
                      }}
                    >
                      Convert to Project
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setPopupModal({
                          show: true,
                          params: CONST_FILED_SERVICE_CREATED,
                          addData: 'Field Service',
                          body: 'Did You really want to convert this Quote to Field Service?',
                        })
                        setAnchorEl(null)
                      }}
                    >
                      Convert To Field Service
                    </MenuItem>
                  </>
                )}
                {type === TimeSheetTextConstant?.WORKORDER && (
                  <>
                    <MenuItem
                      onClick={() => {
                        setPopupModal({
                          show: true,
                          params: CONST_COMPLETE_WORK_ORDER,
                          addData: 'Projects',
                          body: 'Did You really want to mark this Project as completed?',
                        })
                        setAnchorEl(null)
                      }}
                    >
                      Complete Project
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setPopupModal({
                          show: true,
                          params: CONST_FILED_SERVICE_CREATED,
                          addData: 'Field Service',
                          body: 'Did You really want to convert this Project to Field Service?',
                        })
                        setAnchorEl(null)
                      }}
                    >
                      Convert To Field Service
                    </MenuItem>
                  </>
                )}
                {/* <MenuItem onClick={pdfGenerate}>Generate PDF</MenuItem> */}
                <MenuItem
                  onClick={() => {
                    updateLabel(data?.label, false)
                  }}
                >
                  Edit {type.charAt(0).toUpperCase() + type.slice(1)} Number
                </MenuItem>
                <MenuItem onClick={() => updateLabel(data?.label, true)}>
                  Create Duplicates
                  {/* {type.charAt(0).toUpperCase() + type.slice(1)} */}
                </MenuItem>
                {HAS_QuoteUpdateStatus(currentUser) &&
                  TIMESHEET_TEXT === TimeSheetTextConstant?.QUOTE && (
                    <MenuItem onClick={openStatusModal}>
                      Change {type.charAt(0).toUpperCase() + type.slice(1)} Status
                    </MenuItem>
                  )}
                {HAS_WorkOrderUpdateStatus(currentUser) &&
                  TIMESHEET_TEXT === TimeSheetTextConstant?.WORKORDER && (
                    <MenuItem onClick={openStatusModal}>
                      Change {type.charAt(0).toUpperCase() + type.slice(1)} Status
                    </MenuItem>
                  )}
                {HAS_FieldServiceUpdateStatus(currentUser) &&
                  TIMESHEET_TEXT === TimeSheetTextConstant?.FIELDSERVICE && (
                    <MenuItem onClick={openStatusModal}>
                      Change {type.charAt(0).toUpperCase() + type.slice(1)} Status
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={() => {
                    setDeleteModal(true)
                    setAnchorEl(null)
                  }}
                >
                  Delete {type.charAt(0).toUpperCase() + type.slice(1)}
                </MenuItem>
              </Menu>
            </div>
            <div className='d-flex'>
              {
                <button
                  onClick={checkClockIn}
                  type='button'
                  className={`btn ${value === 1 ? 'btn-success' : 'btn-primary'}`}
                  disabled={loading}
                >
                  {
                    <>
                      <If condition={value === 0}>{!loading && 'Clock In '}</If>
                      <If condition={value === 1}>{!loading && 'Clock Out'} </If>
                    </>
                  }
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProfileHeader }
