/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useContext, useEffect, useRef, useMemo, useState} from 'react'
import DataTable from 'react-data-table-component'
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {HeaderAction, HeaderContext} from '../../../context/HeaderContext'
import {useAuth} from '../../../modules/auth'
import {
  currnetCompanyName,
  workorderList,
  FETCH_tagsForWorkOrder,
} from '../../../modules/auth/core/_requests'
import {QuotesModel} from '../../../modules/model/quotes'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {ExportToCsv} from 'export-to-csv'
import {
  statusWorkOptions_bgColorInitial as bgColorInitials,
  statusWorkOptions_textColorInitial as textColorInitial,
} from '../../../modules/accounts/components/settings/SettingsModel'
import {HAS_CreateProject, HAS_ViewWorkOrder} from '../PermissionFunction'
import CloseIcon from '@mui/icons-material/Close'
import {CheckExistance, useLocalStorage} from '../GlobalComponents'
import MaterialReactTable from 'material-react-table'
import moment from 'moment'
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {KTSVG} from '../../../../_metronic/helpers'
import {AsideMenuItem} from '../../../../_metronic/layout/components/aside/AsideMenuItem'
import {AgGridReact} from 'ag-grid-react'
import blank from '../../../../_metronic/layout/components/header/blank.png'
import {MaterialDataTable} from '../MatrialDataTable'
import {Modal} from 'react-bootstrap'
import CustomStatus from '../CustomStatus'
import FilterByTag from '../FilterByTag'
import {Quote} from '../Quote'

const WorkOrderTable = () => {
  const {currentUser} = useAuth()
  const location = useLocation()
  const [createModal, setCreateModal] = useState(false)
  const [totalRows, seTotalRows] = useState(0)
  const navigate = useNavigate()
  const [data, setData] = useState<QuotesModel[]>([])
  const headerContext = useContext<any>(HeaderContext)
  const [loading, setLoading] = useState(true)
  const [selectedTags, setSelectedTags] = useState([])
  const company = currnetCompanyName(currentUser?.company)
  const [selectClient, setSelectClient] = useState<string[] | any>([])
  const [modalHeading, setModalHeading] = useState<string>('') // Initialize with an empty string
  const [modalTitle, setModalTitle] = useState<string>('') // Initialize with an empty string

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const [filtersT, setfiltersT] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const {id} = useParams()
  const [option, setOption] = useState<any>([])
  const SELECTED_ALL = 'all'
  const [selectedClient, setSelectedClient] = useState(SELECTED_ALL)
  const [selected, setSelected] = useState<any>([])
  const isAllSelected = option.length > 0 && selected.length === option.length
  const containerStyle = useMemo(() => ({width: '100%', height: '95%'}), [])
  const gridStyle = useMemo(() => ({height: '100%', width: '100%'}), [])
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [tableStorage, setTableStorage] = useLocalStorage('workorderColsVisibility', '')
  const [modal, setModalShow] = useState<any>(false)
  const [tag, setTag] = useState<any>('all')
  const _selectStatus = useRef<any>('all')
  const _selectTag = useRef<any>('all')
  const _selectClient = useRef<any>('id asc')

  const HandleCloseModal = useCallback(() => {
    setModalShow(false)
  }, [])

  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])

  const columnDefs: any = [
    {
      headerName: 'WO#',
      field: 'label',
      selector: (row: QuotesModel) => 'WO-' + row.label,
      sortable: true,
      cellStyle: {color: 'red', cursor: 'pointer'},
      width: 210,
      suppressSizeToFit: true,

      style: {
        color: 'red',
      },
    },
    {
      headerName: 'Customer',
      field: 'customer.company',
      cellStyle: {cursor: 'pointer'},

      width: 220,

      selector: (row: QuotesModel) => row.customer.company,
    },
    {
      headerName: 'Project Name',
      field: 'project_name',
      cellStyle: {cursor: 'pointer'},

      width: 220,
      selector: (row: QuotesModel) => CheckExistance(row.project_name),
    },

    {
      headerName: 'Date Created',

      field: 'date_created',
      cellStyle: {cursor: 'pointer'},

      width: 220,

      selector: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
    },
    {
      headerName: 'Last Modified',

      field: 'last_modified',
      cellStyle: {cursor: 'pointer'},

      width: 230,

      selector: (row: QuotesModel) => moment(row.last_modified, 'DD-MM-YYYY').format('ll'),
    },
    {
      headerName: 'Status',
      field: 'status_type.title',
      cellStyle: {cursor: 'pointer', color: 'rgb(20,105,83)'},

      width: '275px',
      selector: (row: QuotesModel) => (
        <Link to={`/${company}/project/${row?.id}/overview`} state={{data: row}}>
          <span
            style={{
              backgroundColor: `${row.status_type.bgcolor ?? 'black'}`,
              color: `${row.status_type.color ?? 'white'}`,
              fontSize: '11px',
              textAlign: 'center',
              padding: '5px 15px',
              display: 'block',
              maxWidth: 'fit-content',
            }}
            title={row.status_type.title}
          >
            {row.type}
          </span>
        </Link>
      ),
    },
  ]

  const selectOrderBy = [
    {
      title: 'Project Number',
      path: '/media/icons/duotune/general/gen072.svg',
      value: ['id', 'asc'],
      value1: 'Asc',
      classname: 'svg-icon-6',
    },
    {
      title: 'Project Number',
      path: '/media/icons/duotune/general/gen073.svg',
      value: ['id', 'dsc'],
      value1: 'Dsc',
      classname: 'svg-icon-6',
    },
    {
      title: 'Date Created',
      path: '/media/icons/duotune/general/gen072.svg',
      value: ['date_created', 'asc'],
      value1: 'Asc',
      classname: 'svg-icon-6',
    },
    {
      title: 'Date Created',
      path: '/media/icons/duotune/general/gen073.svg',
      value1: 'Dsc',
      value: ['date_created', 'dsc'],

      classname: 'svg-icon-6',
    },
    {
      title: 'Last Modified',
      path: '/media/icons/duotune/general/gen072.svg',
      value: ['last_modified', 'asc'],
      value1: 'Asc',
      classname: 'svg-icon-6',
    },
    {
      title: 'Last Modified',
      path: '/media/icons/duotune/general/gen073.svg',
      value: ['last_modified', 'dsc'],
      value1: 'Dsc',
      classname: 'svg-icon-6',
    },
  ]

  useEffect(() => {
    setLoading(true)
    const search = searchParams.get('str') as string
    _selectStatus.current = _selectStatus.current.length > 0 ? _selectStatus.current : 'all'
    getList(1, search, _selectStatus.current, _selectTag.current, _selectClient.current)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    }
  }, [])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Projects',
        subtitle: `Total Records`,
        btn_text: 'None',
        additional_btn_text: ' ',
      },
      searchBar: {
        search: true,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    setLoading(true)
    getList()
    headerContext.dispatch(stateHeader)
  }, [])
  const columns: any = [
    {
      name: 'WO#',
      selector: (row: QuotesModel) => 'WO-' + row.label,
      sortable: true,
      style: {
        color: 'red',
      },
    },
    {
      name: 'Customer',
      selector: (row: QuotesModel) => row.customer.company,
    },
    {
      name: 'Project Name',
      selector: (row: QuotesModel) => CheckExistance(row.project_name),
    },
    {
      name: 'Date Created',
      selector: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
    },
    {
      name: 'Last Modified',
      selector: (row: QuotesModel) => moment(row.last_modified, 'DD-MM-YYYY').format('ll'),
    },
    {
      name: 'Status',
      width: '275px',
      selector: (row: QuotesModel) => (
        <Link to={`/${company}/project/${row?.id}/overview`} state={{data: row}}>
          <span
            style={{
              backgroundColor: `${row.status_type.bgcolor ?? '#D0E0CF'}`,
              color: `${row.status_type.color ?? '#054701'}`,
              fontSize: '11px',
              textAlign: 'center',
              padding: '5px 15px',
              display: 'block',
              maxWidth: 'fit-content',
            }}
            title={row.status_type.title}
          >
            {row.type}
          </span>
        </Link>
      ),
    },
  ]

  useEffect(() => {
    setLoading(true)
    getList(
      pagination.pageIndex,
      '',
      SELECTED_ALL,
      _selectTag.current,
      _selectClient.current,
      pagination.pageSize
    )
  }, [pagination])

  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    getList(
      getPagination?.pageIndex,
      '',
      SELECTED_ALL,
      SELECTED_ALL,
      _selectClient.current,
      getPagination?.pageSize
    )
  }, [])
  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      workorder_type = SELECTED_ALL,
      tag_type: string[] | string = SELECTED_ALL,
      sort_type = 'id asc',
      pagePerEntries: number = 100
    ) => {
      await workorderList(page, str, workorder_type, tag_type, sort_type, pagePerEntries, id)
        .then((res) => {
          let workorders = res.data.quotes as QuotesModel[]
          console.log({workorders})
          setData(workorders)
          seTotalRows(res?.data?.totalRows)
          setOption(res?.data?.status || [])
          const stateHeader = {
            payload: {subtitle: `${res.data.totalRows} Total Records`},
            type: HeaderAction.Update,
          }
          setLoading(false)
          headerContext.dispatch(stateHeader)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    []
  )

  const handleTagFunctionality = useCallback((tag_data: string[] | string) => {
    setLoading(true)
    _selectStatus.current = _selectStatus.current.length > 0 ? _selectStatus.current : 'all'
    setTag(tag_data)
    getList(0, '', _selectStatus.current, tag_data, _selectClient.current, 100)
  }, [])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleChange = useCallback((event: any) => {
    setLoading(true)
    const value = event.target.value
    if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
      setSelected([])
      getList(0, '', SELECTED_ALL, _selectTag.current, _selectClient.current)
      return
    } else {
      setSelected(value)
      getList(0, '', value, _selectTag.current, _selectClient.current)
    }
  }, [])
  useEffect(() => {
    _selectStatus.current = selected
    _selectTag.current = tag
    _selectClient.current = selectClient
  }, [selected, tag])
  // console.log('options', option)

  useEffect(() => {
    setfiltersT(headerContext?.state?.searchBar?.search_text)
  }, [headerContext?.state?.searchBar?.search_text])

  useEffect(() => {
    setLoading(true)

    getList(1)
  }, [])
  console.log(data)

  const CSV_HEADER = useMemo(
    () => [
      'Project Id',
      'Customer Name',
      'Project Name',
      'Assigned To',
      'Tags',
      'Date Created',
      'Last Modified',
      'Status Type',
    ],
    []
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx) => {
        return [
          'P-' + eachIdx?.label,
          eachIdx?.customer?.company,
          eachIdx?.project_name,
          eachIdx?.assigned_to[0]?.first_name + ',' + eachIdx?.assigned_to[0]?.email,
          eachIdx?.tags,
          moment(eachIdx?.date_created, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          moment(eachIdx?.last_modified, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          eachIdx?.status_type?.title,
        ]
      }),
    [data]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  useEffect(() => {
    const stateHeader = {
      searchBar: {
        search: false,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    setfiltersT(headerContext?.state?.searchBar?.search_text)
  }, [headerContext?.state?.searchBar?.search_text])

  const filteredItems = data.filter((item) => {
    if (filtersT.length >= 2) {
      return item?.customer.company.toLowerCase().includes(filtersT.toLowerCase())
    } else {
      return data
    }
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleActives(event: any) {
    setLoading(true)
    setSelectClient(event.target.value as string) // (page, str, workorder_type,tag_type,id, sort_type, pagePerEntries)
    getList(1, '', SELECTED_ALL, _selectTag.current, event.target.value)
  }
  const openModal = () => {
    setModalShow(1)
    setAnchorEl(null)
    setModalHeading('Edit Project Status')
    setModalTitle('Add New Project Status')
  }
  // console.log(option)
  // console.log(data)

  // const fetchTags = useCallback(() => {
  //   console.log('id', data)
  //   setLoading(true)

  //   data.forEach((item) => {
  //     console.log('id', item.id)

  //     FETCH_tagsForWorkOrder(item.id || '')
  //       .then(({data}) => {
  //         console.log({data}, data?.result[0]?.tags)
  //         const response = data?.result[0]?.tags
  //         // setSelectedTags(response || [])

  //       setSelectedTags((prevTags) => [...prevTags, response || []])
  //         setLoading(false)
  //       })
  //       .catch((e) => {
  //         setLoading(false)
  //         console.error(e)
  //       })
  //   })
  // }, [data])
  // useEffect(() => {
  //   fetchTags()
  // }, [fetchTags])
  // console.log({selectedTags})
  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: QuotesModel) => 'P-' + row.label,
        size: 70,
        header: 'P#', //uses the default width from defaultColumn prop
        accessorKey: 'workorder_id',
      },
      {
        accessorFn: (row: QuotesModel) => row.customer.company,
        header: 'Customer',
        accessorKey: 'customer_name',
      },
      {
        header: 'Project Name',
        accessorFn: (row: QuotesModel) => row.project_name,
        size: 200, //increase the width of this column
        accessorKey: 'project_name',
      },
      {
        header: 'Assigned To',
        accessorFn: (row: QuotesModel) =>
          row?.assigned_to ? (
            row?.assigned_to.map((eachUser) => {
              return (
                <Tooltip
                  title={
                    <span style={{fontSize: '15px'}}>
                      {eachUser?.first_name + '  ' + eachUser?.last_name || 'N/A'}
                    </span>
                  }
                  className='p-2 '
                >
                  <span>
                    <img
                      alt='emp logo'
                      src={`${eachUser?.profile_image || '/media/avatars/blank.png'}`}
                      onError={(evt: any) => {
                        evt.target.src = blank
                      }}
                      width='40px'
                      height={'40px'}
                      className='mx-1'
                      style={{objectFit: 'contain', borderRadius: '50%'}}
                    />
                  </span>
                </Tooltip>
              )
            })
          ) : (
            <></>
          ),
        size: 200, //increase the width of this column
        accessorKey: 'assigned_to',
      },
      // {
      //   accessorFn: (row: any) => console.log({selectedTags}),
      //   header: 'Selected Tag',
      //   size: 100, //decrease the width of this column
      //   accessorKey: 'selected_tags',
      // },
      {
        accessorFn: (row: any) => row?.tags,
        header: 'Tags',
        size: 100, // decrease or increase the width of this column as needed
        accessorKey: 'selected_tags',
      },
      {
        accessorFn: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
        header: 'Date Created',
        size: 100, //decrease the width of this column
        accessorKey: 'date_created',
      },
      {
        accessorFn: (row: QuotesModel) => moment(row.last_modified, 'DD-MM-YYYY').format('ll'),
        header: 'Last Modified',
        size: 100, //decrease the width of this column
        accessorKey: 'last_modified',
      },
      {
        accessorFn: (row: QuotesModel) => (
          <a
            href={`#`}
            style={{
              backgroundColor: `${row?.status_type?.bgcolor}`,
              color: `${row?.status_type?.color}`,
              fontSize: '11px',
              textAlign: 'center',
              padding: '5px 15px',
              display: 'block',
              maxWidth: 'fit-content',
            }}
          >
            {row?.status_type?.title}
          </a>
        ),
        header: 'Status',
        size: 170, //decrease the width of this column
        accessorKey: 'workorder_status',
      },
    ],
    [selectedTags]
  )
  return (
    <>
      <div>
        <div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card mb-2'></div>
            </div>
          </div>
        </div>
      </div>
      <div style={containerStyle}>
        {/* <div style={gridStyle} className='ag-theme-alpine '> */}

        <div style={gridStyle} className='sticky_header_data_table'>
          <MaterialDataTable
            MaterialColumns={MaterialColumns}
            stopRedirection={HAS_ViewWorkOrder(currentUser)}
            MaterialColumnsApiData={data}
            totalRows={totalRows}
            loadingBar={loading}
            tableHeight={700}
            navigationLink={'project'}
            additionalNavigationLink={'overview'}
            serverPagination={pagination}
            setServerPagination={(cb_data: any) => {
              return PaginationCallback(cb_data)
            }}
            columnVisibility={tableStorage}
            cbColumnVisibility={(callbackData) => {
              cbFunction(callbackData)
            }}
            optionalTableHeader={
              <div className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom check'>
                <div className='d-flex mui-filter-wrap check'>
                  <div>
                    <h4 className='fw-bolder m-5 w-115px'>
                      <KTSVG path='/media/custom/Project.svg' className='svg-icon-2 me-3' />
                      Projects
                    </h4>
                  </div>
                  <div className='god-child'>
                    <div className='temp'>
                      <UsersListSearchComponent />
                    </div>
                    <FormControl className='temp h-35 c-width100'>
                      <InputLabel style={{marginTop: '-6px'}} id='mutiple-select-label'>
                        Filter By Status
                      </InputLabel>
                      <Select
                        style={{height: '35px'}}
                        labelId='mutiple-select-label'
                        multiple
                        value={selected}
                        label='Filter By Status'
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(',')}
                      >
                        <MenuItem value={SELECTED_ALL}>
                          <CloseIcon>
                            <Checkbox
                              checked={isAllSelected}
                              indeterminate={selected.length > 0 && selected.length < option.length}
                            />
                          </CloseIcon>
                          <ListItemText primary='Clear All' />
                        </MenuItem>
                        {option.map((item: any) => (
                          <MenuItem value={item.title}>
                            <ListItemIcon>
                              <Checkbox checked={selected.indexOf(item.title) > -1} />
                            </ListItemIcon>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className='temp'>
                      <FilterByTag
                        callback_function={(cb_Data: string[] | string) => {
                          handleTagFunctionality(cb_Data)
                        }}
                      />
                    </div>
                    <FormControl className='temp h-35 c-width100'>
                      <InputLabel style={{marginTop: '-6px'}} id='all-customers'>
                        Sort By{' '}
                      </InputLabel>
                      <Select
                        style={{height: '35px'}}
                        labelId='All Customers'
                        value={selectClient}
                        label='Sort By'
                        onChange={handleActives}
                        // renderValue={(selectClient) => selectClient.join(',')}
                        renderValue={(selectClient) =>
                          selectClient
                            .map((val: string) => {
                              const selectedOption = selectOrderBy.find(
                                (option) => option.value[0] === val
                              )

                              const selectedPathOption = selectOrderBy.find(
                                (option) => option.value[1] === val
                              )

                              const title = selectedOption ? selectedOption.title : ''
                              const path = selectedPathOption ? selectedPathOption.value1 : ''

                              return title + path
                            })
                            .join(', ')
                        }
                      >
                        {selectOrderBy.map((val) => (
                          <MenuItem value={val.value}>
                            {val.title}
                            <KTSVG className={val.classname} path={val.path} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='mui-icons'>
                    <div
                      className='flex-wrap fw-bold fs-6 pe-2 m-1'
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <button
                        style={{backgroundColor: 'lightgrey'}}
                        className='btn-lightgrey btn h-35px w-35px'
                        id='basic-button'
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                      >
                        <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                          <KTSVG
                            className='svg-icon-4 m-0'
                            path='/media/icons/duotune/general/setting.svg'
                          />
                        </div>
                      </button>
                      <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <>
                          <MenuItem onClick={handleExportData}>
                            <span>Export All Data</span>
                          </MenuItem>
                          <MenuItem onClick={openModal}>
                            <span>Edit Project Statuses</span>
                          </MenuItem>
                          <MenuItem onClick={() => navigate(`/${prefixCompany}/project/tags`)}>
                            <span> Edit Tags </span>
                          </MenuItem>
                        </>
                      </Menu>
                    </div>
                    <div>
                      {HAS_CreateProject(currentUser) && (
                        <button
                          className='btn btn-primary h-35px w-35px fw-bold'
                          onClick={() => setCreateModal(true)}
                        >
                          <div style={{marginTop: '-10px', marginLeft: '-6px', fontSize: '20px'}}>
                            +
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modal}
        onHide={HandleCloseModal}
        onModalClose={HandleCloseModal}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <CustomStatus
          getList={getList}
          type='projects'
          closeModal={HandleCloseModal}
          heading={modalHeading}
          title={modalTitle}
        />
      </Modal>
      <Quote
        modal={createModal}
        setModal={(cb_value: boolean) => {
          setCreateModal(cb_value)
        }}
        getWorkOrderData={getList}
      />
    </>
  )
}

export {WorkOrderTable}
