/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {
  IInvitation,
  IInvitationInitials as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PageTitle} from '../../../_metronic/layout/core'
import {currnetCompanyName, darkMessage, inviteUser} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useEffect} from 'react'
import RolesDropDown from '../../../_metronic/partials/content/dropdown/RolesDropDown'
import Roles from './Roles'
import {onKeyDown} from './GlobalComponents'
const regex = /\d\d\d\d/g
const profileDetailsSchema = Yup.object().shape({
  role: Yup.string().required('Role is required'),
  fName: Yup.string().required('First name is required'),
  phone: Yup.string().required('Phone Number is required').min(14),
  lName: Yup.string().required('Last name is required'),
  title: Yup.string().required('Title is required'),
  pin: Yup.string().matches(regex, 'Must be four Digits').max(4).required('PIN is required'),
  email: Yup.string().email('Must be a valid email').required('Email is required'),
})

const Invite: React.FC = () => {
  // const [data, setData] = useState<IInvitation>(initialValues)
  // const updateData = (fieldsToUpdate: Partial<IInvitation>): void => {
  //   const updatedData = Object.assign(data, fieldsToUpdate)
  //   setData(updatedData)
  // }

  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [inputValue, setInputValue] = useState<any>(null)

  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Send Invitation to Employee',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/employee/view`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])
  const formik = useFormik<IInvitation>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)

      // const updatedData = Object.assign(data, values)
      // setData(updatedData)
      let fullName: string = `${values.fName} ${values.lName}`
      setTimeout(() => {
        inviteUser(
          fullName,
          values.pin,
          values.email,
          values.role,
          values.phone,
          values.title,
          values.birthday,
          values.anniversary,
          values.hometown
        )
          .then(({data}) => {
            setLoading(false)
            setSuccessBody(data as any)
            formik.resetForm()
            darkMessage('Email has been send successfully with Invitation link.')
            navigate(-1)
          })
          .catch((e) => {
            if (e.response.status === 409) {
              setErrorBody(e.response.data)
              setLoading(false)
            }
          })
      }, 2 * 100)
    },
  })

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleError = (e: any) => {
    setTimeout(() => formik.setFieldTouched('phone', true))
  }
  const handleInput = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setInputValue(formattedPhoneNumber)
    formik.setFieldValue('phone', formattedPhoneNumber)
  }

  return (
    <>
      {/* <Toolbar1 btnText={"Back to Employee List"} btnAction={() => {
        navigate(`/${prefixCompany}/employees`)
      }} /> */}

      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SENDINVITE'})} </PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='d-flex p-5 align-items-center m-0'>
            <h3 className='fw-bolder m-0'>Invite Your Employees to Join Prosper Portal!</h3>
          </div>
        </div>

        {errorBody && <ErrorAlert error_response={errorBody} />}
        {successBody && <SuccessAlert success_response={successBody} />}

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        {...formik.getFieldProps('fName')}
                      />
                      {formik.touched.fName && formik.errors.fName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.fName}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        {...formik.getFieldProps('lName')}
                      />
                      {formik.touched.lName && formik.errors.lName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.lName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Emplyoee PIN
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Enter PIN'
                    {...formik.getFieldProps('pin')}
                  />
                  {formik.touched.pin && formik.errors.pin && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.pin}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Phone Number
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone Number'
                    required
                    onBlur={(e) => handleError(e)}
                    onChange={(e) => handleInput(e)}
                    value={inputValue}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>
                <div className='col-lg-8 fv-row'>
                  <RolesDropDown
                    defaultValue={Roles.Engineering.value}
                    onChange={(evt: any) => {
                      formik.setFieldValue('role', evt?.target?.value)
                    }}
                  />
                  {formik.touched.role && formik.errors.role && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.role}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Title</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Title'
                    {...formik.getFieldProps('title')}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Home Town</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Home Town'
                    {...formik.getFieldProps('hometown')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Birthday</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Birthday'
                    {...formik.getFieldProps('birthday')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Anniversary</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Anniversary'
                    {...formik.getFieldProps('anniversary')}
                  />
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || !formik.isValid}
              >
                {!loading && 'Send Invitation'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export {Invite}
