import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PageTitle} from '../../../../_metronic/layout/core'
import {
  addQuote,
  customerQuoteList,
  darkMessage,
  fetchNextQuote,
  currnetCompanyName,
} from '../../../modules/auth/core/_requests'
import ErrorAlert, {ErrorType} from '../../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../../modules/errors/SuccessAlert'
import {QuoteStepper} from '../../../context/QuoteContext'
import fields_step from '../../../data/quoteV2.json'
import clsx from 'clsx'
import {onKeyDown} from '../GlobalComponents'
import {Editor} from '@tinymce/tinymce-react'
import {Autocomplete, TextField} from '@mui/material'
import {CustomerModel} from '../../../modules/model/customer'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
let validationSchemaObject: any = {}
let initialValues: any = {}
const fields_data = fields_step.step2 as any
Object.keys(fields_step.step2).forEach((item: any) => {
  let field = fields_data[item]
  initialValues[item] = item?.default_value
  field?.nullable ? (
    (validationSchemaObject[item] = Yup.string().required(field?.label + ' is required'))
  ) : (
    <></>
  )
})

const validationSchema = Yup.object().shape(validationSchemaObject)

interface PropsType {
  setModal: (callback_values: boolean) => void
  getWorkOrderData: () => void
  dispatchStepper: () => void
}
const Step2 = ({setModal, getWorkOrderData, dispatchStepper}: PropsType) => {
  const qsContext: any = useContext(QuoteStepper)
  const intl = useIntl()
  const [quote_number, setQuote_number] = useState<string>('0')
  const [loading, setLoading] = useState(false)
  const editorRef: any = useRef(null)
  const [options, setOptions] = useState<any>([])
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const navigate = useNavigate()

  const {currentUser}: any = useAuth()
  const Work_Order_Type =
    qsContext?.state?.step_1?.project_type === 0
      ? 'Quote: Q-'
      : qsContext?.state?.step_1?.project_type === 1
      ? 'Project: P-'
      : qsContext?.state?.step_1?.project_type === 2
      ? 'Field Service: FS-'
      : 'Quote: Q-'
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = []
      const form1 = qsContext.state.step_1
      const form2 = values
      params.push(form1)
      params.push(form2)
      setLoading(true)
      addQuote(params, quote_number)
        .then(({data}) => {
          setLoading(false)
          setModal(false)
          getWorkOrderData()
          dispatchStepper()
          setSuccessBody(data as any)
          formik.resetForm()
          if (values.project_type === 0) {
            darkMessage('Quote created Successfully')
          }
          if (values.project_type === 1) {
            darkMessage('Project created Successfully')
          }
          if (values.project_type === 2) {
            darkMessage('Field Service created Successfully')
          }
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setErrorBody(e.response.data)
          }

          setLoading(false)
        })
    },
  })

  const handleCustomer = useCallback(() => {
    navigate(`/${prefixCompany}/customers/create-customer`)
  }, [navigate, prefixCompany])
  useEffect(() => {
    let subscribe = true
    async function getList() {
      await customerQuoteList(null)
        .then((res: any) => {
          if (subscribe) {
            let employes = res.data.customers as CustomerModel[]
            let list: any = []
            employes.forEach((e) => {
              list.push({label: e.company, id: e.id})
            })
            console.log(list)
            setOptions(list)
          }
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setLoading(false)
          }
        })
    }
    getQuote()
    getList()
  }, [])

  const getQuote = useCallback(async () => {
    setLoading(true)
    await fetchNextQuote()
      .then((res: any) => {
        const quote_number = res?.data?.Data
        setQuote_number(quote_number)
        setLoading(false)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setLoading(false)
        }
      })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.QUOTE'})} </PageTitle>
      <div className='card mb-4 mb-xl-2'>
        <div id='kt_account_profile_details' className='collapse show'>
          {errorBody && <ErrorAlert error_response={errorBody} />}
          {successBody && <SuccessAlert success_response={successBody} />}

          <form onSubmit={formik.handleSubmit} noValidate className='form' onKeyDown={onKeyDown}>
            <div className='p-0'>
              <h1 className='text-center p-5 d-flex justify-content-center align-items-center'>
                New {Work_Order_Type}
                <input
                  type='text'
                  style={{width: '150px', height: '5px'}}
                  className='form-control form-control-lg form-control-solid  m-0'
                  value={quote_number}
                  onChange={(e) => {
                    const inputValue = e.target.value
                    // Check if the input matches the allowed pattern before updating the state
                    if (/^[0-9_-]+$/.test(inputValue) || inputValue === '') {
                      setQuote_number(inputValue)
                    }
                  }}
                  maxLength={10}
                  pattern='^[0-9_-]+$'
                  title='Only numbers, underscores, and hyphens are allowed.'
                />
              </h1>

              {Object.keys(fields_step.step2).map((key: string) => {
                let field = fields_data[key]
                return (
                  <>
                    {field?.type === 'select' ? (
                      <div className='row mb-8 ' key={key}>
                        <label
                          className={`col-lg-12 col-form-label fw-bold fs-6 ${
                            field?.nullable ? 'required' : ''
                          }`}
                        >
                          {field.label}
                        </label>
                        <br />
                       
                          <Autocomplete
                            disablePortal
                            className=' form-control-lg '
                            id='combo-box-demo'
                            options={options}
                            onChange={(e, value: any) => {
                              if (value?.id) {
                                formik.setFieldValue('customer_id', value?.id)
                              }
                            }}
                            fullWidth={true}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                label='Please select Customer'
                                style={{height: '5px', paddingTop: 0}}
                              />
                            )}
                            noOptionsText={
                              <div>
                                <button
                                className='Primary'
                                  style={{
                                    marginTop: '9px',
                                    marginLeft: '180px',
                                    // backgroundColor: 'rgb(199,229,255)',
                                    color: 'rgb(107,176,255)',
                                    height: '35px',
                                    width: '250px',
                                    border: 'none',
                                    fontSize: '15px',
                                    borderRadius: '7px 7px 7px 7px',
                                  }}
                                  type='button'
                                  onClick={handleCustomer}
                                >
                                  {' '}
                                  + Create New Customer
                                </button>
                              </div>
                            }
                          />
                        {formik.touched[key] && formik.errors[key] && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors[key]}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : field?.type === 'textarea' ? (
                      <div className='row mb-4 mt-4' key={key}>
                        <label
                          className={`col-lg-12 col-form-label fw-bold fs-6 ${
                            field?.nullable ? 'required' : ''
                          }`}
                        >
                          {field.label}
                        </label>
                        <div className='col-lg-12 fv-row'>
                          <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            onEditorChange={(stringifiedHtmlValue) => {
                              formik.setFieldValue(key, stringifiedHtmlValue)
                            }}
                            init={{
                              height: 200,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                              content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }}
                            {...formik.getFieldProps(key)}
                          />

                          {formik.touched[key] && formik.errors[key] && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors[key]}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className='row mb-4' key={key}>
                        <label
                          className={`col-lg-12 col-form-label fw-bold fs-6 ${
                            field?.nullable ? 'required' : ''
                          }`}
                        >
                          {field.label}
                        </label>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            disabled={loading}
                            className={clsx(
                              'form-control form-control-lg ',
                              {
                                'is-invalid': formik.touched[key] && formik.errors[key],
                              },
                              {
                                'is-valid': formik.touched[key] && !formik.errors[key],
                              }
                            )}
                            {...formik.getFieldProps(key)}
                          />
                          {formik.touched[key] && formik.errors[key] && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors[key]}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )
              })}

              <div className='row'>
                <div className='col-md-6 text-left'>
                  <button
                    type='button'
                    disabled={loading}
                    onClick={() => qsContext.dispatch({type: 'BACK'})}
                    className='btn btn-lg btn-primary me-3'
                  >
                    <span className='indicator-label'>Back</span>
                  </button>
                </div>
                <div className='col-md-6 text-right'>
                  <button type='submit' disabled={loading} className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export {Step2}
