import {useCallback, useContext, useEffect, useState} from 'react'
import {FC} from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import {HeaderAction} from '../../context/HeaderContext'
import {HeaderContext} from '../../context/HeaderContext'
import {companyFilesList, customLoading} from '../../modules/auth/core/_requests'
import {KTSVG} from '../../../_metronic/helpers'
import SimpleDataTable from './SimpleDataTable'

const CompanyFiles: FC = () => {
  const headerContext = useContext<any>(HeaderContext)
  const [formArray, setFormArray] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'Company Files',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    fetchQuoteStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ClipBoard = useCallback((idx: any) => {
    navigator.clipboard.writeText(idx?.file_path)
    customLoading()
  }, [])

  const columns: any = [
    {
      header: 'File Name',
      accessorFn: (row: any) => <div>{row.file_name}</div>,
    },
    {
      header: 'Project',
      accessorFn: (row: any) => <div>{row.workorder}</div>,
    },

    {
      header: 'Upload By',
      accessorFn: (row: any) => <div>{row?.user?.first_name + ' ' + row?.user?.last_name}</div>,
    },
    {
      header: 'Link',
      id: 'button',
      accessorFn: (row: any, id: any) => (
        <>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-light btn-active-light-primary'
            onClick={(e) => {
              ClipBoard(row)
            }}
          >
            <span className='svg-icon svg-icon-5 m-0'>
              <KTSVG path='/media/custom/link.svg' className='svg-icon-1' />
            </span>
          </button>
        </>
      ),
    },
  ]

  const fetchQuoteStatus = () => {
    setLoading(true)
    companyFilesList()
      .then((res) => {
        if (res.data.response) {
          setFormArray(res.data.response)
          setLoading(false)
        }
      })
      .catch((e) => {
        console.log('e', e)
        setLoading(false)
      })
  }

  return (
    <>
      <div className='card'>
        <SimpleDataTable
          MaterialColumns={columns}
          MaterialColumnsApiData={formArray}
          loadingBar={loading}
        />
      </div>
    </>
  )
}

export default CompanyFiles
