import {useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {Editor} from '@tinymce/tinymce-react'

type PropsType = {
  cb_fnc: (data: any) => void
  previous_data?: string
}

export function InventoryNotes({cb_fnc, previous_data}: PropsType) {
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const editorRef: any = useRef(null)

  const initialValues = {
    option: '',
    notes: '',
  }

  const Options_validations = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    validationSchema: Options_validations,
    onSubmit: (values) => {
      setLoading(true)
      const data = {
        additional_notes: editorRef.current.getContent(),
        inventory_id: id,
      }
      cb_fnc(data)
      setLoading(false)
    },
  })

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2 className='fw-bolder m-2'>Add Notes</h2>
          <form onSubmit={formik.handleSubmit} noValidate className='form m-10'>
            <div className='row g-6 g-xl-9'>
              <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={previous_data || ''}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />

              <button
                type='submit'
                className='btn btn-primary '
                disabled={loading || !formik.isValid}
              >
                {!loading && `Save Notes`}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
