import {FC, useCallback, useContext, useEffect, useState} from 'react'
import {TimeSheetConstant, TimeSheetTextConstant} from '../../../../app/context/TimeSheetConstant'
import {useAuth} from '../../../../app/modules/auth'
import {
  currentlyClockIn,
  currnetCompanyName,
  modelClockInHandle,
  modelClockOutHandle,
} from '../../../../app/modules/auth/core/_requests'
import {ProfileContext} from '../../MasterLayout'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useParams} from 'react-router-dom'

export function ClockInModal(props: any) {
  const {currentUser}: any = useAuth()
  const companyName: any = currnetCompanyName(currentUser?.company)
  const [titleLastTimer, setTitleLastTimer] = useState()
  const [clockInLink, setClockInLink] = useState('')
  const profileContext: any = useContext(ProfileContext)
  // Modal realated  state and function
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clockData, setClockData] = useState<any>(false)

  const handleModel = async () => {
    setLoading(true)
    await modelClockInHandle()
      .then((resp) => {
        if (resp) {
          setLoading(false)
          setClockData(resp.data)
          setShow(false)
          console.log('clock in', resp.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleModelClockOut = async () => {
    setLoading(true)
    await modelClockOutHandle()
      .then((resp) => {
        if (resp) {
          setLoading(false)
          setShow(false)
          setClockData(false)
          console.log('clock out', resp.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  // End Modal realated state and function
  const lastLoginedTime = useCallback(() => {
    currentlyClockIn()
      .then((res) => {
        const title = res?.data?.clocked_in?.final_title
        const id = res?.data?.clocked_in?.quote?.id
        const type =
          res?.data?.clocked_in?.timesheet_type === TimeSheetConstant.QUOTE
            ? TimeSheetTextConstant.QUOTE
            : TimeSheetTextConstant.WORKORDER
        const link = `/${companyName}/${type}/${id}/overview`
        setTitleLastTimer(title)
        if (id) {
          setClockInLink(link)
        } else {
          setClockInLink('')
        }
      })
      .catch(() => {
        setTitleLastTimer(undefined);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(() => {
      lastLoginedTime()
    }, 1000 * 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    lastLoginedTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext?.state?.clockIn])
  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
      show={props?.show}
      size='lg'
      onHide={props?.onModalClose}
    >
      <Modal.Header>
        <Modal.Title>
          <h3 className='p-2 lh-base'>{props?.description}</h3>
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>Do you want to Clock In?</Modal.Body> */}
      <Modal.Footer>
        <Button variant='primary' onClick={props?.onModalClose}>
          Cancel
        </Button>
        <Button variant='secondary' onClick={props?.handleSubmit}>
          {props?.value ? 'Clock Out' : 'Clock In'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
