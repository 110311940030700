/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {StatusOpt} from './StatusOpt'
import {
  darkErrorMessage,
  darkMessage,
  quoteAdditionalNotes,
  quoteDisclaimer,
  GetDisclaimer,
} from '../../modules/auth/core/_requests'
import {useParams} from 'react-router-dom'
import {Button, TextField} from '@mui/material'
import {Editor} from '@tinymce/tinymce-react'
import {ApiContext} from './ProfilePage'

const optionsData = [
  {value: 'General'},
  {value: 'Customer Communication'},
  {value: 'Status Update'},
]
const MARKUPUNIT = [
  {
    value: '$',
  },
  {
    value: '%',
  },
]
const LASTWISH = [
  {
    value: '15 Days',
  },
  {
    value: '30 Days',
  },
  {
    value: '45 Days',
  },
  {
    value: '60 Days',
  },
  {
    value: '90 Days',
  },
  {
    value: '120 Days',
  },
]
const communicationType = [{value: 'Phone'}, {value: 'Email'}, {value: 'In Person'}]
const optionConst = {
  GENERAL: 'General',
  CUSTOMER_COMMUNICATION: 'Customer Communication',
  STATUS_UPDATE: 'Status Update',
}

export function AddNote(props: any) {
  const contextValue = useContext<any>(ApiContext)
  const employeeList = contextValue.emp_list
  const mentionRegex = /@(\w+)/g
  const {type, id} = useParams()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<any>(null)
  const editorRef: any = useRef(null)
  const [readOnly, setReadOnly] = useState<any>(true)
  const [newDisclaimer, setNewDisclaimer] = useState<any>('')
  const [taxValue, setTaxValue] = useState<any>('')
  const [defaultMarkupValueUnit, setDefaultMarkupValueUnit] = useState<any>('')
  const [defaultMarkupValue, setDefaultMarkupValue] = useState<any>('')
  const [selectedClient, setSelectedClient] = useState()

  const updateOptions = (e: any) => {
    setSelected(e?.target?.value)
  }

  const getDisclaimer = useCallback(async () => {
    await GetDisclaimer()
      .then(({data}) => {
        setLoading(false)
        setNewDisclaimer(data.quote_disclaimer)
        setSelectedClient(data.quote_disclaimer.expire_days)
        setTaxValue(data.quote_disclaimer.default_tax)
        setDefaultMarkupValue(data.quote_disclaimer.default_markup_value)
        setDefaultMarkupValueUnit(data.quote_disclaimer.default_markup_value_unit)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          let message: any = e?.response?.data?.error_list[0]
          // setErrorBody(error)
          darkErrorMessage(message)
          setLoading(false)
        } else {
          console.log(e)
        }
      })
  }, [])
  useEffect(() => {
    getDisclaimer()
  }, [getDisclaimer])

  useEffect(() => {
    formik.setFieldValue('option', selected)
  }, [selected])

  const initialValues = {
    option: '',
    notes: '',
  }

  const Options_validations = Yup.object().shape({
    // option: Yup.string().required(`Option Selection is required`),
    // notes: Yup.string().required(`Notes are required`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Options_validations,
    onSubmit: (values) => {
      setLoading(true)
      let userString = editorRef.current.getContent()

      if (userString !== '') {
        userString = userString.replace(new RegExp('<p>', 'gi'), '')
        userString = userString.replace(new RegExp('</p>', 'gi'), '')
      }

      let data = {
        type: values?.option,
        additonal_notes: userString,
        defaultTax: taxValue,
        workorder_id: id,
        expireDays: selectedClient,
        defaultMarkupValueUnit: defaultMarkupValueUnit,
        defaultMarkupValue: defaultMarkupValue,
      }

      // eslint-disable-next-line no-lone-blocks
      {
        props?.disclaimer
          ? quoteDisclaimer(data)
              .then((res) => {
                setLoading(false)
                darkMessage(res.data.message)
                props?.onModalClose()
              })
              .catch((e) => {
                console.error(e)
                darkErrorMessage('Error')
                setLoading(false)
                props?.onModalClose()
              })
          : quoteAdditionalNotes(data)
              .then((res) => {
                props?.getData()
                setLoading(false)
                darkMessage('Additonal Notes created Successfully')
                props?.onModalClose()
              })
              .catch((e) => {
                props?.getData()
                console.error(e)
                darkErrorMessage('Error')
                setLoading(false)
                props?.onModalClose()
              })
      }
    },
  })

  const handleChange = (content: any, editor: any) => {
    const inputValue = editor.getContent({format: 'text'})
    const matches = inputValue.match(mentionRegex)
    const filteredList = employeeList.filter((eachEmp: any) => {
      return matches.some((match: string) => {
        return eachEmp.first_name.toLowerCase().includes(match.toLowerCase())
      })
    })
  }

  const handleTaxValue = (v: any) => {
    setTaxValue(v.target.value)
  }
  const handleDefaultMarkupValue = (v: any) => {
    setDefaultMarkupValue(v.target.value)
  }
  const handleDefaultMarkupValueUnit = useCallback((event: any) => {
    console.log(event.target.value)
    setDefaultMarkupValueUnit(event.target.value)
  }, [])
  const handleActive = useCallback((event: any) => {
    console.log(event.target.value)
    setSelectedClient(event.target.value)
  }, [])
  console.log(defaultMarkupValue, defaultMarkupValueUnit, newDisclaimer)
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2 className='fw-bolder m-2'>{props?.disclaimer ? 'Quote Settings' : 'Add Notes'}</h2>
          <form onSubmit={formik.handleSubmit} noValidate className='form m-10'>
            {props.disclaimer && !props.subQuoteDisclaimer && (
              <TextField
                className='mt-6'
                id='outlined-basic'
                label='Default Tax Percentage'
                type='number'
                variant='outlined'
                InputProps={{readOnly: readOnly}}
                onChange={handleTaxValue}
                value={taxValue}
                fullWidth
              />
            )}
            <br />
            <br />
            <br />
            {props.disclaimer && !props.subQuoteDisclaimer && (
              <div className='col-md-12'>
                <h2>Default Markup Value</h2>
                <TextField
                  className='mt-6'
                  id='outlined-basic'
                  label='Default Markup Value'
                  type='number'
                  variant='outlined'
                  InputProps={{readOnly: readOnly}}
                  onChange={handleDefaultMarkupValue}
                  value={defaultMarkupValue}
                  fullWidth
                />
              </div>
            )}
            <br />
            <br />
            <br />
            {props.disclaimer && !props.subQuoteDisclaimer && (
              <div className='col-md-12'>
                <select
                  className='form-control form-control-lg form-control-solid w-100'
                  value={defaultMarkupValueUnit}
                  onChange={handleDefaultMarkupValueUnit}
                >
                  {MARKUPUNIT.map((choices: any) => {
                    return <option value={choices?.value}>{choices?.value}</option>
                  })}
                </select>
              </div>
            )}
            <br />
            <br />
            <br />
            {props.disclaimer && !props.subQuoteDisclaimer && (
              <div className='col-md-12'>
                <h2>Quotes Expire After..</h2>
                <select
                  className='form-control form-control-lg form-control-solid w-100'
                  value={selectedClient}
                  onChange={handleActive}
                >
                  {/* <option value={selectedClient} selected hidden disabled>
                     {selectedClient}
                   </option> */}

                  {LASTWISH.map((choices: any) => {
                    return <option value={choices?.value}>{choices?.value}</option>
                  })}
                </select>
              </div>
            )}
            <br />
            <br />
            <br />
            <h2 className='fw-bolder '>{props?.disclaimer ? 'Terms & Conditions' : 'Add Notes'}</h2>
            <div className='row g-6 g-xl-9'>
              <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={props?.disclaimer ? newDisclaimer?.quote_disclaimer : ''}
                disabled={props?.disclaimer && readOnly}
                onChange={handleChange}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />

              {props?.disclaimer && (
                <div style={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
                  {readOnly && (
                    <div onClick={() => setReadOnly(false)} className='btn btn-secondary'>
                      Edit Default Quote
                    </div>
                  )}
                  {!readOnly && (
                    <button
                      type='submit'
                      className='btn btn-primary '
                      disabled={loading || !formik.isValid}
                    >
                      {!loading && `Save Disclaimer`}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  )}
                </div>
              )}

              {!props?.disclaimer && (
                <button
                  type='submit'
                  className='btn btn-primary '
                  disabled={loading || !formik.isValid}
                >
                  {!loading && `Save Note`}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
