/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {ChangePassword} from './components/ChangePassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { RegistrationInvite } from './components/RegistrationInvite'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid desktopHalfRedBg'>
        <div className="container-fluid p-0">
          <div className="row m-0 flex-row-reverse">
            <div className="col-12 col-md-6 p-0 text-center mobileHalfRedBg mb-5">
              {/* begin::Logo */}
                <div className="brandImg">
                  <a href='#' className='mb-12'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/white_logo_color_background.jpg')} className='h-100px' />
                  </a>
                  <img className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="https://preview.keenthemes.com/metronic8/demo1/assets/media/misc/auth-screens.png" alt=""></img>
                </div>

{/*
                <div className="text-white fs-base text-center">
                  <ul className="list-unstyled inline-menu">
                    <li><a href="">Terms</a></li>
                    <li><a href="">Plans</a></li>
                    <li><a href="">Contact Us</a></li>
                  </ul>
						    </div>

*/}
                 {/* end::Logo */}
            </div>
            <div className="col-12 col-md-6 p-0 text-center">
              {/* begin::Wrapper */}
              <div className='mainFormOuter'>
                <Outlet />
              </div>              
              {/* end::Wrapper */}
            </div>
          </div>
        </div>
        
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='forgot-password/change-password/:token/:id' element={<ChangePassword />} />
      <Route path='accept/invite/:token/:id' element={<RegistrationInvite />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
